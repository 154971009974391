import React, { useState, useRef, useEffect } from "react"
import { navigate } from "gatsby"
import ReCAPTCHA from "react-google-recaptcha"
import { Box, Button, FormField, Grommet, TextInput, TextArea } from "grommet"
import { deepMerge } from "grommet/utils"
import { grommet } from "grommet/themes"
import validate from "../utils/formValidator"
import contactFormStyles from "./contactForm.module.scss"
import { displayError } from "../utils/errorHandler"
import Spinner from "./spinner"

const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY
if (typeof RECAPTCHA_KEY === "undefined") {
  throw new Error(`Env var is undefined!`)
}

const encode = data =>
  Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")

const customContactFormTheme = deepMerge(grommet, {
  global: {
    colors: {
      brand: "#6699cc"
    },
    focus: {
      border: {
        color: "#6699cc"
      }
    },
    font: {
      family: "Roboto",
      size: "18px"
    },
    input: {
      weight: 300
    }
  }
})

const ContactForm = () => {
  const recaptchaRef = useRef()
  const [state, setState] = useState({
    name: "",
    email: "",
    message: "",
    captchaSuccessful: false,
    canSubmit: false,
    isSubmitting: false
  })

  const {
    name,
    email,
    message,
    captchaSuccessful,
    canSubmit,
    isSubmitting
  } = state
  useEffect(() => {
    const canSubmitForm =
      validate({ name, email, message }) && captchaSuccessful
    setState(prevState => ({ ...prevState, canSubmit: canSubmitForm }))
  }, [name, email, message, captchaSuccessful])

  const handleChange = e =>
    setState({ ...state, [e.target.name]: e.target.value })

  const handleCaptchaChange = value => {
    let captchaSuccessful = false
    if (recaptchaRef.current) {
      captchaSuccessful = value ? true : false
    }
    setState({ ...state, captchaSuccessful })
  }

  const handleSubmissionError = () => {
    setState({ ...state, isSubmitting: false })
    displayError("network")
  }

  const handleResponseStatus = res => {
    if (!res.ok) {
      throw new Error(res.statusText)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setState({ ...state, isSubmitting: true })
    const form = e.target
    const {
      canSubmit,
      captchaSuccessful,
      isSubmitting,
      ...stateToSubmit
    } = state
    const recaptchaValue = recaptchaRef.current.getValue()

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        "g-recaptcha-response": recaptchaValue,
        ...stateToSubmit
      })
    })
      .then(handleResponseStatus)
      .then(() =>
        navigate(form.getAttribute("action"), {
          state: { email: stateToSubmit.email }
        })
      )
      .catch(handleSubmissionError)
  }

  return (
    <Grommet theme={customContactFormTheme}>
      <Box align="start" flex>
        <Box alignContent="start" pad="small" flex>
          <form
            name="contact"
            method="post"
            action="/success"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            data-netlify-recaptcha="true"
            onSubmit={handleSubmit}
          >
            <noscript>
              <p>
                Sorry, you seem to have JavaScript disabled. This form won’t
                work without JavaScript.
              </p>
            </noscript>
            <p hidden>
              <label>
                Don’t fill this out:
                <input name="bot-field" onChange={handleChange} />
              </label>
            </p>
            <FormField label="Your Name" width="400px">
              <TextInput name="name" value={name} onChange={handleChange} />
            </FormField>
            <FormField label="Your Email" width="400px">
              <TextInput name="email" value={email} onChange={handleChange} />
            </FormField>
            <FormField label="Message" name="message" width="800px">
              <TextArea
                className={contactFormStyles.messageTextArea}
                name="message"
                value={message}
                onChange={handleChange}
              />
            </FormField>
            <div className={contactFormStyles.gRecaptcha}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={RECAPTCHA_KEY}
                onChange={handleCaptchaChange}
                onErrored={handleSubmissionError}
              />
            </div>
            <Box margin={{ top: "medium" }} width="small">
              <div className={contactFormStyles.submitContainer}>
                <Button
                  type="submit"
                  label={
                    <div className={contactFormStyles.submitContainer}>
                      {isSubmitting && <Spinner />}
                      Send
                    </div>
                  }
                  disabled={!canSubmit || isSubmitting}
                  style={{
                    font: "Roboto",
                    color: "ButtonText",
                    fontWeight: 300
                  }}
                />
              </div>
            </Box>
          </form>
        </Box>
      </Box>
    </Grommet>
  )
}

export default ContactForm
