const errorMapper = {
  network:
    "Sorry, there was an error. Please check your internet connection before retrying.",
  generic: "Sorry, there was an error. Please try again."
}

export const displayError = type =>
  type && type in errorMapper
    ? alert(errorMapper[type])
    : alert(errorMapper.generic)
