const REG_EX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

const validate = ({ name, email, message }) => {
  const nameAndMessageExist = !Object.values({ name, message }).some(
    inputVal => inputVal.trim().length === 0
  )
  return nameAndMessageExist && REG_EX.test(email)
}

export default validate
