import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Head from "../components/head"
import contactStyles from "./contact.module.scss"
import ContactForm from "../components/contactForm"
const ContactMePage = () => {
  return (
    <Layout isHome={false}>
      <Head
        title="Contact Me"
        description="Online counselling with intersectional approach to Cognitive Behavioural Therapy (CBT). Receive and practice unconditional acceptance towards yourself."
      />
      <h2>Contact Me</h2>
      <div className={contactStyles.infoContainer}>
        <p>
          For any questions not addressed in the <Link to="/faq/">FAQs</Link>,
          or to arrange a free confidential 15-minute introductory phone call
          about how we can work together, you can contact me in the following
          ways:
        </p>
        <ul>
          <li>
            Email:{" "}
            <a href="mailto:hello@gavinlashleytherapy.com">
              hello@gavinlashleytherapy.com
            </a>
          </li>
          <li>Use the contact form below</li>
        </ul>
        <p>
          I aim to reply to emails and form submissions within two working days.
        </p>
      </div>
      <div className={contactStyles.formContainer}>
        <ContactForm />
      </div>
    </Layout>
  )
}

export default ContactMePage
